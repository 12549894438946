import { AuthGuard } from './session/auth.guard';
import { Routes } from '@angular/router';
//Layouts
import { 
  CondensedComponent,
  LoginComponent
} from './@pages/layouts';

export const AppRoutes: Routes = [

  {
    path: '',
    canActivate: [AuthGuard] ,
    component: CondensedComponent,
    children: [{
      path: '',
      loadChildren: './home/home.module#HomeModule'
    }],
  },
  {
    path: '',
    component: LoginComponent,
    children: [{
      path: 'session',
      loadChildren: './session/session.module#SessionModule'
    }]
  },
  {
    path: '',
    component: CondensedComponent,
    children: [{
      path: 'email',
      loadChildren: './email/email.module#EmailModule'
    }]
  },
  {
    path: '',
    component: CondensedComponent,
    children: [{
      path: 'financeiro',
      loadChildren: './financeiro/financeiro.module#FinanceiroModule'
    }]
  },
  {
    path: '',
    component: CondensedComponent,
    children: [{
      path: 'usuario',
      loadChildren: './usuario/usuario.module#UsuarioModule'
    }]
  },
  {
    path: '',
    canActivate: [AuthGuard] ,
    component: CondensedComponent,
    children: [{
      path: 'pagamento',
      loadChildren: './pagamento/pagamento.module#PagamentoModule'
    }]
  },
  {
    path: '',
    canActivate: [AuthGuard] ,
    component: CondensedComponent,
    children: [{
      path: 'reserva',
      loadChildren: './reserva/reserva.module#ReservaModule'
    }]
  }
];
