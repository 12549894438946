import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpHeaders
} from '@angular/common/http';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor() {}
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = localStorage.getItem('token');
  
      if (token && !req.url.startsWith('https://viacep.com.br')) { // Fazer de uma forma melhor
        const cloned = req.clone({
          headers: req.headers.set('Authorization', 'JWT '.concat(token))
        });
  
        return next.handle(cloned);
      } else {
        return next.handle(req);
      }
    }
}