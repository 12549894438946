import { Component, OnInit, OnDestroy, ViewChild, HostListener, AfterViewInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  @ViewChild('root') root;
  constructor() { }

  ngOnInit() {
  }

}
