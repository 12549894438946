import { Component, OnInit, OnDestroy, ViewChild, HostListener, AfterViewInit, Input, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';

@Component({
  selector: 'condensed-layout',
  templateUrl: './condensed.component.html',
  styleUrls: ['./condensed.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CondensedComponent extends RootLayout implements OnInit {

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('cpfUsuarioLogado');
  }

  menuLinks = [
    {
      label: "Início",
      details: "Alugue um Veículo",
      routerLink: "/",
      iconType: "fa",
      iconName: "car",
      thumbNailClass: "bg-success"
    },
    // {
    //   label: "Financeiro",
    //   iconType: "fa",
    //   iconName: "money",
    //   toggle: "close",
    //   submenu: [
    //     {
    //       label: "Histórico",
    //       routerLink: "/financeiro/extrato",
    //       iconType: "letter",
    //       iconName: "his",
    //     },
    //   ]
    // },
    {
      label: "Meu Perfil",
      routerLink: "/usuario/perfil",
      iconType: "fa",
      iconName: "user"
    },
    {
      label: "Sair",
      routerLink: "/session/login",
      iconType: "fa",
      iconName: "sign-out"
    }
  ];

  ngOnInit() {
  }

}
